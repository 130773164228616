"use client";

import React from "react";
import { Modal } from "react-bootstrap";
import ViewSamplePopUpSection from "./viewSample";

interface viewModelProps {
  product: any;
  slug: string;
  show: boolean; // Changed from sampleShow to show
  handleClose: () => void;
}

const ViewModal: React.FC<viewModelProps> = ({ product, slug, show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered  dialogClassName="custom-modal"> 
      <Modal.Header closeButton className="custom-model-head">
        <Modal.Title className="fw-bold">Get it now, pay later</Modal.Title> 
      </Modal.Header>
      <Modal.Body className="p-0">
        <ViewSamplePopUpSection product={product} slug ={slug}/>
      </Modal.Body>
    </Modal>
  );
};

export default ViewModal;
