"use client";
import React, { useState } from "react";
import Image from "next/image";
import {
  addProductToCart,
  createCart,
  createCheckout,
  fetchCart,
  updateCartLineQuantity,
} from "@/utils/Shopify/products";
import { useCart } from "@/context/CartContext";
import { BannerSlider } from "@/types/product-content";
import CartModal from "./cartModel";
import ViewModal from "./viewModel";

export default function ViewSamplePopUpSection({ product, slug }: any) {
  const { itemCount, setItemCount } = useCart();
  const { cart, setCart } = useCart();
  const [productIndex, setProductIndex] = useState<any>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [clickedItem, setClickedItem] = useState<any>(null);
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [cartShowModel, setCartShowModel] = useState<boolean>(false);
  const [sampleShowModel, setSampleShowModel] = useState<boolean>(false);

  let variantId = "";

  const handleCartShow = () => {
    setCartShowModel(true);
  };
  const handleCartClose = () => {
    setCartShowModel(false);
    handleSampleClose();
  };
  const handleSampleClose = () => {
    setSampleShowModel(false);
  };

  const handleAddToCart = async (productId: string, variantIds: string[]) => {
    if (slug !== "book") {
      variantId = variantIds[0];
    } else if (slug === "book") {
      if (productIndex === 1) {
        variantId = variantIds[1];
      } else if (productIndex === 0) {
        variantId = variantIds[0];
      }
    }

    let storedData = localStorage.getItem("cartCheckoutData");
    let cartId = storedData ? JSON.parse(storedData).cartId : null;

    if (cartId) {
      // Fetch the existing cart data
      const cartData = await fetchCart(cartId);

      // Check if the product already exists in the cart
      let existingLineItem;
      if (variantId) {
        // If there's a variant, check if the variant already exists in the cart
        existingLineItem = cartData?.lines.edges.find(
          (edge: any) => edge.node.merchandise.id === variantId
        );
      } else {
        // If no variant, find the product by productId
        existingLineItem = cartData.lines.edges.find(
          (edge: any) => edge.node.merchandise.product.id === productId
        );
      }

      let updatedCart;
      if (existingLineItem) {
        // If the product (variant or simple product) already exists in the cart, update the quantity
        updatedCart = await updateCartLineQuantity(
          cartId,
          existingLineItem.node.id,
          existingLineItem.node.quantity + 1
        );
        setCart(updatedCart);
      } else {
        // If the product (variant or simple product) is new, add it to the cart
        if (variantId) {
          // If there's a variant, add the product with variant
          updatedCart = await addProductToCart(cartId, variantId, 1);
        } else {
          // If it's a simple product, add it by productId
          updatedCart = await addProductToCart(cartId, productId, 1);
        }
        setCart(updatedCart);
      }

      // Recalculate and update the total item count based on the updated cart
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);

      // Update local storage with current cartId and checkoutId (if any)
      storedData = localStorage.getItem("cartCheckoutData");
      const { checkoutId } = storedData
        ? JSON.parse(storedData)
        : { checkoutId: null };
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId, checkoutId })
      );
    } else {
      // If no cart ID exists, create a new cart
      const newCartId = await createCart();
      const newCheckoutId = null;
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId: newCartId, checkoutId: newCheckoutId })
      );

      // Fetch the newly created cart
      const cartData = await fetchCart(newCartId);
      setCart(cartData);

      // Add the product to the newly created cart
      let updatedCart;
      if (variantId) {
        // If there's a variant, add the product with variant
        updatedCart = await addProductToCart(newCartId, variantId, 1);
      } else {
        // If it's a simple product, add it by productId
        updatedCart = await addProductToCart(newCartId, productId, 1);
      }
      setCart(updatedCart);

      // Recalculate and update the item count
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);
    }
  };
  return (
    <>
      <CartModal cartShow={cartShowModel} handleClose={handleCartClose} />
      <ViewModal  product={product} slug={slug} show={sampleShowModel} handleClose={handleSampleClose} />

      <div className="bg-white  mx-auto p-4 view-sample">
        <div>
          <p className="sample-para">Sample plans for $199.00 purchase</p>
        </div>
        <div className="card px-3 py-5">
          <div className="mx-4">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h2 className="duration">
                <strong>$49.75 </strong>/ 2 weeks
              </h2>
              <p className="fw-bold duration-time mb-0">8 weeks</p>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <div>
                  <p className="title">APR</p>
                  <p className="title-cal">0%</p>
                </div>
              </div>
              <div className="col-4">
                <div>
                  <p className="title">Interest</p>
                  <p className="title-cal">$0.00</p>
                </div>
              </div>
              <div className="col-4">
                <div>
                  <p className="title">Total</p>
                  <p className="title-cal">$199.00</p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="mx-4">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h2 className="duration">
                <strong>$17.96 </strong>/ month
              </h2>
              <p className="fw-bold duration-time mb-0">12 months</p>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <div>
                  <p className="title">APR</p>
                  <p className="title-cal m-0">15%</p>
                </div>
              </div>
              <div className="col-4">
                <div>
                  <p className="title">Interest</p>
                  <p className="title-cal m-0">$16.52</p>
                </div>
              </div>
              <div className="col-4">
                <div>
                  <p className="title">Total</p>
                  <p className="title-cal m-0">$215.52</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button
            type="button"
            className="btn w-100 continue-btn my-5"
            onClick={() => {
              handleCartShow();
              handleAddToCart(product.productId, product.variantIds);
            }}
          >
            Add to Cart
          </button>
          <p className="text-center text-para">
            Checking your eligibility won't affect your credit.
          </p>
          <p className="text-para">
            The estimated payment amount excludes taxes and shipping. Rates
            range from 0-36% APR. Payment options through Shop Pay Installments
            are subject to an eligibility check and are provided by these
            lending partners:{" "}
            <a href="" className="text-para">
              affirm.com/lenders
            </a>
            . Options depend on your purchase amount, and a down payment may be
            required. More options may be available upon approval. State notices
            to consumers:{" "}
            <a href="" className="text-para">
              affirm.com/licenses
            </a>
            .
          </p>

          <div className="w-25 mx-auto">
            <Image
              src="/assets/images/new-image/shoppay.png"
              alt="Plus"
              className="card-img-top w-auto"
              width={95}
              height={30}
            />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <p className="mb-0 text-center text-para mt-2 me-3">
            Installments in partnership with
          </p>
          <div>
            <Image
              src="/assets/images/new-image/affirm.png"
              alt="Plus"
              className="card-img-top w-auto"
              width={95}
              height={30}
            />
          </div>
        </div>
      </div>
    </>
  );
}
